
export default {
  name: 'MaisonSlider',
  props: {
    maisons: {},
    withGallery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        spaceBetween: 24,
        slidesPerView: 1.2,
        slidesPerGroup: 1,
        threshold: 15,
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
          1200: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
        },
      },
    };
  },
};
